<template>
    <div class="list pb-nav">
        <TitleHeader :title="title"  link="/my"/>

        <ul class="basic_list d-flex flex-wrap flex-column p-3" v-if="notice_list&&notice_list.length>0">
            <li v-for="(item,index) in notice_list" :key="index" class="d-flex justify-content-between mb-3 border-bottom pb-3">
                <router-link :to="`/cs/notice/${item.idx}`" class="left d-flex w-100">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-truncate">{{ item.title }}</span>
                        <small class="text-body text-opacity-50"><span class="small">{{ item.date }}</span></small>
                    </div>
                </router-link>
            </li>
        </ul>
        
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                게시글이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        BasicList,
        TitleHeader
    },
    data(){
        return{
            title:'공지사항',

            notice_list: [
                {
                    idx: 0,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
                {
                    idx: 1,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
                {
                    idx: 2,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
                {
                    idx: 3,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
                {
                    idx: 4,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
                {
                    idx: 5,
                    title: "공지사항의 제목입니다.",
                    date: "2022-12-27"
                },
            ]
        }
    },
    computed:{
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>
</style>