<template>
    <div class="mh-100vh d-flex flex-column">
        <TitleHeader :title="title" />

        <div class="pb-5">
            <div class="py-3">
                <h4 class="text-center">
                    <span class="text-blue">{{nick}}</span> 님!<br>
                    궁금한 점이 있다면<br>
                    언제든지 물어보세요.
                </h4>
            </div>
            <div class="inquiry_quick_box mx-auto mt-3">
                <ul class="d-flex justify-content-center border py-4 px-3 mx-3 rounded shadow-sm">
                    <li class="w-50 text-center">
                        <router-link to="/cs/inquiry/write">
                            <div class="my-1"><i class="fal fa-comment-alt-lines fs-2"></i></div>
                            <p class="small text-body text-opacity-50">1:1 문의</p>
                        </router-link>
                    </li>
                    <li class="vr"></li>
                    <li class="w-50 text-center">
                        <router-link to="/cs/inquiry/list">
                            <div class="my-1"><i class="fal fa-comments-alt fs-2"></i></div>
                            <p class="small text-body text-opacity-50">1:1 문의 답변확인</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="pt-5 px-3 mb-5 pb-5 flex-grow-1">
            <div class="top_box">
                <div class="hstack justify-content-between">
                    <h5>자주찾는 질문</h5>
                    <router-link to="/cs/faq" class="text-body text-opacity-50"><span class="small">더보기+</span></router-link>
                </div>
            </div>
            <div class="my-3">
                <ul class="board_list" v-if="faq&&faq.length>0">
                    <li v-for="(item,index) in faq" :key="index">
                        <div class="txt_box d-flex w-100 align-items-center border-bottom py-3 pe-2" @click="open_faq_idx == item.idx ? open_faq_idx=-1 : open_faq_idx = item.idx">
                            <b class="me-2 pe-1">Q.</b>
                            <p class="tit ">{{item.title}}</p>
                            <i class="fal small ps-3 ms-auto" :class="{'fa-chevron-down':open_faq_idx != item.idx, 'fa-chevron-up':open_faq_idx == item.idx}"></i>
                        </div>
                        <div class="txt_box small px-3 py-5 bg-xl-dark border-bottom" v-if="open_faq_idx == item.idx">
                            {{item.desc}}
                        </div>
                    </li>
                </ul>  
                <div class="empty_data" v-else>
                    <p class="text-center py-5">
                        준비중입니다.
                    </p>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '고객센터',

            nick: '',
            faq: [
                {
                    idx: 0,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 1,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 2,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 3,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 4,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
            ],

            // front ui
            open_faq_idx: -1,

        }
    },
    
    mounted(){
        this.nick = this.$store.state.my_info.nick;
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
    .inquiry_quick_box{
        max-width: 500px;
    }
</style>