<template>
    <div class="notice list pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column py-3 px-3 mb-3">
            <h6 class="mb-1">{{item.title}}</h6>
            <small class="mb-4 text-opacity-50 text-body">{{item.date}}</small>
            <div class="small desc" v-html="item.desc"></div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'공지사항',
            item:{
                title: '공지사항의 제목입니다.',
                date: '2022-12-27',
                desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis sit dolorum molestiae. Vel, reprehenderit quis facere beatae corrupti odio voluptates sapiente saepe tempore tempora neque non optio iure, in maxime eveniet deserunt accusantium odit dicta culpa a autem. Cupiditate nulla molestias harum, quisquam id fugit fuga est quaerat doloribus quod dolores debitis perspiciatis provident, minima obcaecati odio deleniti. Iste in aliquam quibusdam minus, libero nihil aperiam harum quidem reprehenderit, dicta voluptas nisi sit facere delectus consectetur. Ducimus autem, nostrum sint distinctio voluptatibus voluptatem praesentium illo velit ea nobis! Fuga tenetur magnam architecto distinctio, at cumque quam aperiam soluta aspernatur ad.`,
            },
        }
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss">
.notice{
    .desc{
        img{
            display: block;
            width: 100%;
            max-width: 600px;
        }
    }
}
</style>