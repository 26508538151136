<template>
    <div class="list pb-nav">
        <TitleHeader :title="title" link="/my" />

        <div class="tab_find line_tab">
            <ul>
                <li :class="{'on':tab_idx == index}" v-for="(item,index) in ['진행중 이벤트','종료된 이벤트']" :key="index" @click="ChgList(index)">
                    <a href="#">{{item}}</a>
                </li>
            </ul>
        </div>

        <ul class="basic_list d-flex flex-wrap flex-column p-3" v-if="list&&list.length>0">
            <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between mb-3 border-bottom pb-3">
                <router-link :to="`/cs/event/${item.idx}`" class="left d-flex w-100">
                    <img :src="item.thumbnail" class="border ratio ratio-1x1 me-3" alt="event thumbnail" style="width:60px;">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-truncate">{{ item.title }}</span>
                        <small class="text-body text-opacity-50 text-truncate"><span class="small">{{ item.start_date }}</span> ~ <span class="small">{{ item.end_date }}</span></small>
                    </div>
                </router-link>
            </li>
        </ul>
        
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                게시글이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        BasicList,
        TitleHeader
    },
    data(){
        return{
            title:'이벤트',

            event_list: [
                {
                    idx: 0,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 1,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 2,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 3,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
                {
                    idx: 4,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
                {
                    idx: 5,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
            ],

            list: [],

            // front ui
            tab_idx: 0,
        }
    },
    computed:{
    },
    methods:{
        ChgList(index){
            switch (index) {
                case 1:
                    this.list = this.event_list.filter(
                        (e) => e.end == false
                    )
                    break;
                case 2:
                    this.list = this.event_list.filter(
                        (e) => e.end == true
                    )
                    break;
            
                default:
                    this.list = this.event_list;
                    break;
            }

            this.tab_idx = index;
        }
    },
    mounted(){
        this.ChgList(0);

        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>
</style>