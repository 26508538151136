<template>
    <div class="mb-5 pb-5">
        <TitleHeader :title="title" />

        <div class="px-3 mb-4">
            <span class="small text-danger">* 최근 6개월간 문의 내역입니다.</span>
        </div>
        <div class="mx-3">
            <ul class="board_list small" v-if="inquiry&&inquiry.length>0">
                <li v-for="(item,index) in inquiry" :key="index">
                    <div class="txt_box d-flex w-100 align-items-center border-bottom py-3 pe-2" @click="open_inquiry_idx == item.idx ? open_inquiry_idx=-1 : open_inquiry_idx = item.idx">
                        <b class="me-2 pe-1">Q.</b>
                        <p class="tit ">{{item.title}} ({{item.date}})</p>
                        <i class="fal small ps-3 ms-auto" :class="{'fa-chevron-down':open_inquiry_idx != item.idx, 'fa-chevron-up':open_inquiry_idx == item.idx}"></i>
                    </div>
                    <div class="txt_box px-3 py-4 bg-xl-dark border-bottom" v-if="open_inquiry_idx == item.idx">
                        <h6 class="mb-2 text-info">Q.</h6>
                        {{item.q}}
                        <hr class="my-4">
                        <h6 class="mb-2 text-danger">A.</h6>
                        {{item.a}}
                    </div>
                </li>
            </ul>
            
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    문의 내역이 없어요.
                </p>
            </div> 
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '1:1 문의 답변 확인',

            inquiry: [
                {
                    idx: 0,
                    title: '내 문의 제목',
                    date: '2023-01-10',
                    q: '내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...',
                    a: '내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...'
                },
                {
                    idx: 1,
                    title: '내 문의 제목',
                    date: '2023-01-10',
                    q: '내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...',
                    a: '내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...'
                },
                {
                    idx: 2,
                    title: '내 문의 제목',
                    date: '2023-01-10',
                    q: '내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...',
                    a: '내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...'
                },
                {
                    idx: 3,
                    title: '내 문의 제목',
                    date: '2023-01-10',
                    q: '내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...',
                    a: '내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...'
                },
                {
                    idx: 4,
                    title: '내 문의 제목',
                    date: '2023-01-10',
                    q: '내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...내 문의 내용...',
                    a: '내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...내 문의에 대한 답변...'
                },
            ],

            search:null,

            // front ui
            open_inquiry_idx: -1,
        }
    },
    method: {
        searchFn(){

        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>
