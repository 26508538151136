<template>
    <div class="event list pb-nav">
        <TitleHeader :title="title" />
        <div class="d-flex flex-column py-3 px-3 mb-3">
            <h6 class="mb-1">{{item.title}}</h6>
            <small class="mb-4 text-opacity-50 text-body">{{item.start_date}} ~ {{item.end_date}}</small>
            <div class="small desc" v-html="item.desc"></div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'이벤트',
            item:{
                title: '이벤트의 제목입니다.',
                start_date: "2022-12-27",
                end_date: "2023-01-27",
                desc: `<img src="https://via.placeholder.com/600x1000" alt="이미지 속 텍스트 설명">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis sit dolorum molestiae. Vel, reprehenderit quis facere beatae corrupti odio voluptates sapiente saepe tempore tempora neque non optio iure, in maxime eveniet deserunt accusantium odit dicta culpa a autem. Cupiditate nulla molestias harum, quisquam id fugit fuga est quaerat doloribus quod dolores debitis perspiciatis provident, minima obcaecati odio deleniti. Iste in aliquam quibusdam minus, libero nihil aperiam harum quidem reprehenderit, dicta voluptas nisi sit facere delectus consectetur. Ducimus autem, nostrum sint distinctio voluptatibus voluptatem praesentium illo velit ea nobis! Fuga tenetur magnam architecto distinctio, at cumque quam aperiam soluta aspernatur ad.`,
            },
        }
    },
    methods:{
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss">
.event{
    .desc{
        img{
            display: block;
            width: 100%;
            max-width: 600px;
        }
    }
}
</style>