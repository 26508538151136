<template>
    <div class="pb-5 mb-5">
        <TitleHeader :title="title" />

        <div class="container">
            <form>
                <div class="px-3">
                    <div class="form-group">
                        <label class="small text-body text-opacity-50 ms-1">답변 받으실 이메일</label>
                        <div class="input-group">
                            <input class="form-control py-px-12" type="text" required placeholder="답변 받으실 이메일" :disabled="!edit_email_mode" v-model="email">
                            <div class="btn btn-secondary flex-shrink-0 small d-flex align-items-center" @click="edit_email_mode = true"><span>수정</span></div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50 ms-1">문의 제목</label>
                        <input class="form-control py-px-12" type="text" required placeholder="문의 제목" v-model="tit">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50 ms-1">문의 내용</label>
                        <textarea class="post_desc border rounded p-3 w-100 bg-primary text-white" rows="10" v-model="desc" maxlength="1000">
                        </textarea>
                        <div class="d-flex"><span class="ms-auto small">{{desc.length}}/1000</span></div>
                    </div>
                </div>
            </form>
        </div>
        <div class="px-3 mt-5">
            <button class="btn bg-blue py-px-12 mb-3 w-100" @click="Submit()" :disabled="desc.length < 10">
                문의하기
            </button>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '1:1 문의하기',

            email: '',

            tit: '',
            desc: '',

            // front ui
            edit_email_mode: false,
        }
    },
    methods:{
        Submit(){
            alert("정상적으로 제출하였습니다.");
            this.$router.push(`/cs/inquiry/list`)
        }
    },
    
    mounted(){
        this.email = this.$store.state.my_info.email;
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
    .post_desc{
        height: calc(100vh - 520px);
        min-height: 250px;
    }
</style>