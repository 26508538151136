<template>
    <div class="mb-5 pb-5">
        <TitleHeader :title="title" />

        <div class="search_input px-3 position-relative mb-3">
            <div class="input-group">
                <span class="input-group-text bg-black text-white border" id="basic-addon1"><i class="far fa-search"></i></span>
                <input type="search" class="form-control" placeholder="질문 검색" aria-describedby="basic-addon1" v-model="search" v-on:keyup.enter="searchFn()">
            </div>
        </div>
        <div class="mx-3">
            <ul class="board_list" v-if="faq&&faq.length>0">
                <li v-for="(item,index) in faq" :key="index">
                    <div class="txt_box d-flex w-100 align-items-center border-bottom py-3 pe-2" @click="open_faq_idx == item.idx ? open_faq_idx=-1 : open_faq_idx = item.idx">
                        <b class="me-2 pe-1">Q.</b>
                        <p class="tit ">{{item.title}}</p>
                        <i class="fal small ps-3 ms-auto" :class="{'fa-chevron-down':open_faq_idx != item.idx, 'fa-chevron-up':open_faq_idx == item.idx}"></i>
                    </div>
                    <div class="txt_box small px-3 py-5 bg-xl-dark border-bottom" v-if="open_faq_idx == item.idx">
                        {{item.desc}}
                    </div>
                </li>
            </ul>  
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    준비중입니다.
                </p>
            </div>  
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '자주 찾는 질문',

            faq: [
                {
                    idx: 0,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 1,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 2,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 3,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 4,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...'
                },
                {
                    idx: 5,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
                {
                    idx: 6,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
                {
                    idx: 7,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
                {
                    idx: 8,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
                {
                    idx: 9,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
                {
                    idx: 10,
                    title: '자주 찾는 질문 제목',
                    desc: '자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...자주 찾는 질문에 대한 답변...',
                },
            ],

            search:null,

            // front ui
            open_faq_idx: -1,
        }
    },
    method: {
        searchFn(){

        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>
